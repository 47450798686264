import Team from '../../components/Team';
import TimelineAbout from '../../components/Timeline';

export default function About() {
    return (
        <div>
            <TimelineAbout />
            {/* <Team /> */}
        </div>
    );
}
