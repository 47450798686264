import { useRef, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const scrollButton = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (isVisible) {
            gsap.to(scrollButton.current, {
                duration: 0.5,
                opacity: 1,
                zIndex: 100,
            });
        } else {
            gsap.to(scrollButton.current, {
                duration: 0.5,
                opacity: 0,
                zIndex: -1,
            });
        }
    }, [isVisible]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
            left: 0,
        });
        scrollButton.current?.blur();
    };

    const color = useColorModeValue('gray.600', 'gray.400');

    return (
        <IconButton
            aria-label="Scroll to Top"
            icon={<ChevronUpIcon color={color} />}
            onClick={handleClick}
            ref={scrollButton}
            size={'lg'}
            opacity={0}
            zIndex={-1}
            position={'fixed'}
            bottom={'20px'}
            right={'20px'}
        />
    );
};

export default ScrollToTop;
