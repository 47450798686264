import {
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Stack,
    Text,
    useColorModeValue as UseColorModevalue,
    VisuallyHidden,
    chakra,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={UseColorModevalue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: UseColorModevalue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function socialProfileWithImageHorizontal(props: any) {
    const {
        profileName,
        role,
        description,
        imageUrl,
        linkedinUrl,
        instagramUrl,
    } = props;
    return (
        <Center py={6}>
            <Stack
                borderWidth="1px"
                borderRadius="lg"
                w={{ sm: '100%', md: '540px' }}
                height={{ sm: '476px', md: '20rem' }}
                direction={{ base: 'column', md: 'row' }}
                bg={UseColorModevalue('white', 'gray.800')}
                boxShadow={'2xl'}
                padding={4}
            >
                <Flex flex={1} bg="blue.200">
                    <Image objectFit="cover" boxSize="100%" src={imageUrl} />
                </Flex>
                <Stack
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    pt={2}
                >
                    <Heading fontSize={'2xl'} fontFamily={'body'}>
                        {profileName}
                    </Heading>
                    <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
                        {role}
                    </Text>
                    <Text
                        textAlign={'center'}
                        color={UseColorModevalue('gray.700', 'gray.400')}
                        px={3}
                    >
                        {description}
                    </Text>
                    <Stack direction={'row'} spacing={6}>
                        {/* <SocialButton label={'Twitter'} href={'#'}>
                          <FaTwitter />
                      </SocialButton> */}
                        <SocialButton label={'Linkedin'} href={linkedinUrl}>
                            <FaLinkedin />
                        </SocialButton>
                        <SocialButton label={'Instagram'} href={instagramUrl}>
                            <FaInstagram />
                        </SocialButton>
                    </Stack>

                    {/* <Stack
                      width={'100%'}
                      mt={'2rem'}
                      direction={'row'}
                      padding={2}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                  >
                      <Button
                          flex={1}
                          fontSize={'sm'}
                          rounded={'full'}
                          _focus={{
                              bg: 'gray.200',
                          }}
                      >
                          Message
                      </Button>
                      <Button
                          flex={1}
                          fontSize={'sm'}
                          rounded={'full'}
                          bg={'blue.400'}
                          color={'white'}
                          boxShadow={
                              '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                          }
                          _hover={{
                              bg: 'blue.500',
                          }}
                          _focus={{
                              bg: 'blue.500',
                          }}
                      >
                          Follow
                      </Button>
                  </Stack> */}
                </Stack>
            </Stack>
        </Center>
    );
}
