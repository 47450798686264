import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
    useColorModeValue as UseColorModevalue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ReactElement } from 'react';
import {
    FaFlag,
    FaEye,
    FaUserGraduate,
    FaBookOpen,
    FaUsers,
} from 'react-icons/fa';

interface CardProps {
    heading: string;
    description: string;
    icon: ReactElement;
    href: string;
}

const Card = ({ heading, description, icon, href }: CardProps) => {
    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
            variants={{ hhidden: { x: '-100%' }, visible: { x: 0 } }}
        >
            <Box
                maxW={{ base: 'full', md: '275px' }}
                w={'full'}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={5}
            >
                <Stack align={'start'} spacing={2}>
                    <Flex
                        w={16}
                        h={16}
                        align={'center'}
                        justify={'center'}
                        color={'#054285'}
                        rounded={'full'}
                        bg={useColorModeValue('gray.100', 'gray.700')}
                    >
                        {icon}
                    </Flex>

                    <Box mt={2}>
                        <Heading size="md">{heading}</Heading>
                        <Text mt={1} fontSize={'sm'}>
                            {description}
                        </Text>
                    </Box>
                    {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                        Learn more
                    </Button> */}
                </Stack>
            </Box>
        </motion.div>
    );
};

export default function gridListWith() {
    return (
        <Box p={4}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                <Heading
                    fontSize={{ base: '2xl', sm: '4xl' }}
                    fontWeight={'bold'}
                >
                    Get to know us
                </Heading>
                <Text
                    color={UseColorModevalue('gray.700', 'gray.200')}
                    fontSize={{ base: 'sm', sm: 'lg' }}
                >
                    Welcome to YIPA TW, the Young Indonesian Professionals'
                    Association in Taiwan. As a dynamic community organization,
                    we are dedicated to empowering Indonesian professionals in
                    Taiwan to thrive, grow, and stay connected. Discover our
                    mission, values, and the vibrant opportunities we offer to
                    help you succeed in your career and contribute to Indonesia
                    while being abroad.
                </Text>
            </Stack>

            <Container maxW={'5xl'} mt={12}>
                <Flex flexWrap="wrap" gridGap={6} justify="center">
                    <Card
                        heading={'Visions'}
                        icon={<Icon as={FaEye} w={10} h={10} />}
                        description={
                            'To become a go-to-community organization for Indonesian professionals in Taiwan who are passionate about developing themselves while staying connected and/or looking to contribute to Indonesia, despite being based abroad.'
                        }
                        href={'#'}
                    />
                    <Card
                        heading={'Missions'}
                        icon={<Icon as={FaFlag} w={10} h={10} />}
                        description={
                            'Foster business, investment, and social links between Taiwan and Indonesia through a strong professional hub of future leaders.'
                        }
                        href={'#'}
                    />
                    <Card
                        heading={'Mentorships'}
                        icon={<Icon as={FaUserGraduate} w={10} h={10} />}
                        description={
                            'Give back to Indonesian community through dedicated mentorship program, connecting professionals with students to help transition from academics to workforce.'
                        }
                        href={'#'}
                    />
                    <Card
                        heading={'Knowledge Shares'}
                        icon={<Icon as={FaBookOpen} w={10} h={10} />}
                        description={
                            'Provide a platform for initiating discussions and exchanging knowledge on personal development topics relevant to professionals in Taiwan, such as contracts and salary negotiation.'
                        }
                        href={'#'}
                    />
                    <Card
                        heading={'Networking Events'}
                        icon={<Icon as={FaUsers} w={10} h={10} />}
                        description={
                            'Organize networking events to connect Indonesian professionals in Taiwan with each other, as well as with other professionals in Taiwan and Indonesia.'
                        }
                        href={'#'}
                    />
                </Flex>
            </Container>
        </Box>
    );
}
