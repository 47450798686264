import {
    Stack,
    Flex,
    Button,
    Text,
    VStack,
    useBreakpointValue,
    useColorMode,
    Link,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';

export default function WithBackgroundImage() {
    const { colorMode } = useColorMode();
    let backgroundImage =
        'url(https://napheart.sirv.com/Images/site/taipei-day.jpg)';
    if (colorMode === 'dark') {
        backgroundImage =
            'url(https://napheart.sirv.com/Images/site/taipei-night.jpg)';
    }
    return (
        <Flex
            w={'full'}
            h={'90vh'}
            backgroundImage={backgroundImage}
            backgroundSize={'cover'}
            backgroundPosition={'center center'}
        >
            <VStack
                w={'full'}
                justify={'center'}
                px={useBreakpointValue({ base: 4, md: 8 })}
                bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
            >
                <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        initial="hidden"
                        animate="visible"
                        variants={{
                            hidden: { y: '100%' },
                            visible: { y: 0 },
                        }}
                        transition={{ duration: 1 }}
                    >
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '3xl',
                                md: '4xl',
                            })}
                        >
                            Young Indonesian Professionals' Association.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={400}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: 'md',
                                md: 'lg',
                            })}
                        >
                            Started in 2021, we are a group of young Indonesian
                            professionals' in the Taiwan represents as diverse
                            group of talented Indonesian professionals' in
                            Taiwan.
                        </Text>
                    </motion.div>
                    <Stack direction={'row'}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            initial="hidden"
                            animate="visible"
                            variants={{
                                hidden: { x: '-100%' },
                                visible: { x: 0 },
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <Link href="/about">
                                <Button
                                    bg={'blue.400'}
                                    rounded={'full'}
                                    color={'white'}
                                    _hover={{ bg: 'blue.500' }}
                                >
                                    Learn more about us
                                </Button>
                            </Link>
                        </motion.div>
                    </Stack>
                </Stack>
            </VStack>
        </Flex>
    );
}
