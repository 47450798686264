import { Box, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import VerticalTimeline from '../VerticalTimeline';

const events = [
    {
        "date": "December 2021",
        "title": "Grand Launching YIPA TW",
        "description": "December 2021 marked a momentous milestone for the Young Indonesian Professionals Association in Taiwan (YIPA TW) with its Grand Launching event. This inaugural celebration not only officially introduced YIPA TW to the vibrant community of young Indonesian professionals in Taiwan but also set the stage for the association's commitment to fostering professional growth, cultural exchange, and networking opportunities.",
        "imagePath": "https://napheart.sirv.com/Images/events/event1.jpg"
    },
    {
        "date": "February 2022",
        "title": "Data Scientist 101",
        "description": "In February 2022, YIPA TW hosted 'Data Scientist 101,' an enlightening event designed to demystify the field of data science for Indonesian professionals in Taiwan. This workshop offered a comprehensive introduction to the essential skills and tools required in data science, presented by Indonesian professionals in the field.",
        "imagePath": "https://napheart.sirv.com/Images/events/event2.png"
    },
    {
        "date": "March 2022",
        "title": "Product Management 101",
        "description": "In March 2022, YIPA TW organized 'Product Management 101,' a pioneering event tailored to introduce Indonesian professionals in Taiwan to the fundamentals of product management. This session was expertly crafted to provide attendees with a solid foundation in product lifecycle management, strategy development, and user-centric design principles.",
        "imagePath": "https://napheart.sirv.com/Images/events/event3.png"
    },
    {
        "date": "March 2022",
        "title": "Digital Marketing 101",
        "description": "YIPA TW continued its mission to equip members with in-demand skills by hosting 'Digital Marketing 101.' This engaging workshop was aimed at Indonesian professionals in Taiwan looking to grasp the essentials of digital marketing in today's digitally driven world.",
        "imagePath": "https://napheart.sirv.com/Images/events/event4.jpg"
    },
    {
        "date": "May 2022",
        "title": "Hello Future Me",
        "description": "In May 2022, YIPA TW in collaboration with the NTUST International Student Association (NTUST-ISA) presented the 'Hello Future Me' webinar series, specifically designed for students. This forward-looking initiative aimed to inspire and guide students in envisioning and preparing for their future careers.",
        "imagePath": "https://napheart.sirv.com/Images/events/event5.png"
    },
    {
        "date": "June 2022",
        "title": "Long Term Career",
        "description": "In June 2022, YIPA TW addressed the pivotal topic of sustainable career development through its 'Long Term Career' event. This initiative was designed to guide Indonesian professionals in Taiwan towards envisioning and cultivating careers that are not only successful but also fulfilling over the long term.",
        "imagePath": "https://napheart.sirv.com/Images/events/event6.jpg"
    },
    {
        "date": "August 2022",
        "title": "Issues in Workplace",
        "description": "In August 2022, YIPA TW tackled the increasingly important issue of workplace wellness with an event titled 'Issues in the Workplace,' focusing particularly on managing stress in a global work environment. Recognizing the challenges faced by Indonesian professionals in Taiwan, especially in navigating the pressures of an international career, this session aimed to equip participants with effective stress management techniques and strategies for fostering a healthy work-life balance.",
        "imagePath": "https://napheart.sirv.com/Images/events/event7.png"
    },
    {
        "date": "October 2022",
        "title": "YIPA’s End Year Report and Networking Event",
        "description": "YIPA TW celebrated its annual accomplishments and laid out future directions with the 'YIPA's Year-End Report and Networking' event. This significant gathering served as a reflective and forward-looking forum where the achievements of the past year were showcased, including the impact of its various programs, workshops, and community-building efforts.",
        "imagePath": "https://napheart.sirv.com/Images/events/event8.jpg"
    },
    {
        "date": "December 2022",
        "title": "IT-Connects",
        "description": "In December 2022, YIPA TW in collaboration with the Indonesian Economic and Trade Office (IETO) and CrossBond launched the inaugural 'Indonesia-Taiwan Competence Network for Hi-Tech and Industrial Investments' (IT-Connects), marking a significant stride in fostering partnerships between Indonesian talents and Taiwanese high-tech and industrial sectors. This pioneering one-day event featured opening remarks from esteemed guests, highlighting Indonesia's readiness to meet the investment needs of Taiwanese enterprises, especially through its skilled young professionals. IT-Connects facilitated deep dives into companies' operationsshowcasing how Indonesian talent can bolster their expansion efforts.",
        "imagePath": "https://napheart.sirv.com/Images/events/event9.jpg"
    },
    {
        "date": "March 2023",
        "title": "Social Networking Event - Business Talk",
        "description": "In March 2023, YIPA TW in collaboration with PERPITA hosted a pivotal social networking event coupled with an insightful business talk. This gathering featured esteemed guest speakers from KIB Consulting and Fath Capital, who shared their profound insights into the business landscape, offering valuable advice and perspectives to the attendees.",
        "imagePath": "https://napheart.sirv.com/Images/events/event10.jpg"
    },
    {
        "date": "March 2023",
        "title": "Social Networking Event",
        "description": "YIPA TW organized another enriching social networking event that brought together the Indonesian professional community in Taiwan for an evening of connection and insightful dialogue. The highlight of the event was the presence of guest speakers, Mr. Soesanto Wijaya, the founder of Polygon, and Steven Wijaya, the former Secretary General of GIPA.",
        "imagePath": "https://napheart.sirv.com/Images/events/event11.jpg"
    },
    {
        "date": "June 2023",
        "title": "Networking Event and Career Talks",
        "description": "In June 2023, YIPA TW hosted a standout networking event and career talks under the theme 'Expand Your Professional Network While Gaining Valuable Insights from Industry Experts.' This event was meticulously designed to provide Indonesian professionals in Taiwan with an unparalleled opportunity to broaden their professional connections and deepen their industry knowledge. The career talks featured a lineup of esteemed industry experts who shared their experiences, strategies for success, and insights into the latest industry trends and opportunities.",
        "imagePath": "https://napheart.sirv.com/Images/events/event12.jpg"
    },
    {
        "date": "June 2023",
        "title": "IT-Connects: Job-fair",
        "description": "In June 2023, the Indonesian Overseas Community Association (IOCA) and the Indonesian Economic and Trade Office (IETO), in partnership with YIPA Taiwan, successfully hosted the 'IT-Connects: Job Fair.' This landmark event was specifically designed to bridge the gap between Taiwanese employers and Indonesian professionals and students in Taiwan, showcasing a wide array of employment opportunities in various sectors, including technology, manufacturing, and services.",
        "imagePath": "https://napheart.sirv.com/Images/events/event13.jpg"
    },
    {
        "date": "November 2023",
        "title": "IT-Connects 2023: Coffee with Leaders - Path of Choices",
        "description": "In November 2023, YIPA TW, in collaboration with UrInsider and supported by the Indonesian Economic and Trade Office (IETO), orchestrated the insightful event 'Coffee with Leaders: Path of Choices.' This unique gathering invited participants to engage in intimate conversations over coffee with a diverse panel of leaders, including Mr. Ivan Christian, Ivan Halim from Sevenpreneurs, Ms. Aurelia Vizal, a renowned influencer and storyteller, Mr. Vincentius Randy, IT Director at Adbert, and Ms. Amelia Tan from Kith & Kin. Each speaker shared their personal journeys, the decisions that shaped their careers, and insights into overcoming challenges and seizing opportunities.",
        "imagePath": "https://napheart.sirv.com/Images/events/event14.jpg"
    }
]
    ;

const introduction = {
    title: 'About Us',
    description:
        "Welcome to the Young Indonesian Professionals' Association in Taiwan (YIPA TW)—a dynamic hub for Indonesian professionals. United by passion and purpose, our community thrives on empowerment, connection, and shared success in Taiwan’s vibrant landscape. Join us as we navigate, grow, and celebrate our professional journeys together.",
};

export default function Timeline() {
    return (
        <Box bg={useColorModeValue('gray.50', 'gray.600')} py={{ base: 8, md: 12 }}>
            <Stack spacing={1} align={'center'} padding={4}>
                <Heading>About Us</Heading>
                <Text fontSize={{ base: 'md', lg: 'lg' }} maxW={'3xl'}>
                    {introduction.description}
                </Text>
            </Stack>
            <Box maxWidth="800px" mx="auto">
                <VerticalTimeline events={events} />
            </Box>
        </Box>
    );
}
