import { useLocation } from 'react-router-dom';
import Team from '../../components/Team';
import NotFound from '../../components/NotFound';

export default function Staff() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const season = searchParams.get('season');
    return (
        <div>
            {/* Render content based on the 'season' parameter */}
            {season === '1' && <Team season={1} />}
            {season === '2' && <Team season={2} />}
            {/* If the season parameter does not match any of the above, show a NotFound component */}
            {season !== '1' && season !== '2' && <NotFound />}
        </div>
    );
}
