import * as React from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Layout from './pages/Layout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import EventPage from './pages/Event';
import Staff from './pages/Staff';

export const routerList = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'staff',
                element: <Staff />,
            },
        ],
    },
]);

export const App = () => (
    <ChakraProvider theme={theme}>
        <RouterProvider router={routerList} />
    </ChakraProvider>
);
