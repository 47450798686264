import HeroBanner from '../../components/HeroBanner';
import Features from '../../components/Features';
// import Newsletter from '../../components/Newsletter'
import SocialFeed from '../../components/SocialFeed';
import Stats from '../../components/Stats';
import EventList from '../../components/EventList';

export default function Home() {
    return (
        <>
            <HeroBanner />
            <Features />
            {/* <Newsletter /> */}
            <Stats />
            {/* <EventList /> */}
            <SocialFeed />
        </>
    );
}
