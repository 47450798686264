import React from 'react';
import {
    Flex,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure
} from '@chakra-ui/react';

interface DynamicImagePreviewProps {
    imagePath: string;
    title: string;
}

export default function DynamicImagePreview({ imagePath, title }: DynamicImagePreviewProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {/* Flex container for the image */}
            <Flex
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                padding={4}
                cursor="pointer"
                onClick={onOpen}
            >
                <Image src={imagePath} alt={title} />
            </Flex>

            {/* Modal for enlarging the image */}
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Image
                            src={imagePath} // Using the same source for simplicity
                            alt={title}
                            transform="scale(1.8)"
                            objectFit="cover"
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
