// generate multiple card using ProfileCard component
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Stack,
    useColorModeValue,
    Text,
} from '@chakra-ui/react';
import ProfileCard from '../../components/ProfileCard';

const teamData = [
    {
        id: 1,
        profileName: 'Iman Adipurnama',
        description: 'Director of YIPA Taiwan',
        role: 'Executive Director',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        linkedinUrl: 'https://www.linkedin.com/in/imanadipurnama/',
        instagramUrl: 'https://www.instagram.com/imanadipurnama/',
    },
    {
        id: 2,
        profileName: 'Michael W Pratama',
        role: 'Vice Director',
        description: 'Vice Director of YIPA Taiwan',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        linkedinUrl: 'https://www.linkedin.com/in/michaelwpratama/',
        instagramUrl: 'https://www.instagram.com/michaelwpratama/',
    },
    {
        id: 3,
        profileName: 'Jefferson Singgih',
        role: 'Vice Director',
        description: 'Vice Director of YIPA Taiwan',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        linkedinUrl: 'https://www.linkedin.com/in/jeffersonsinggih/',
        instagramUrl: 'https://www.instagram.com/jeffersonsinggih/',
    },
    {
        id: 4,
        profileName: 'Rosdyana Kusuma',
        role: 'Technical Director',
        description: 'Technical Director of YIPA Taiwan',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        linkedinUrl: 'https://www.linkedin.com/in/rosdyanakusuma/',
        instagramUrl: 'https://www.instagram.com/rosdyanakusuma/',
    },
    {
        id: 5,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 6,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 7,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 8,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 9,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 10,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 11,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 12,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 13,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
    {
        id: 14,
        profileName: 'Jane Doe',
        role: 'Backend Developer',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
    },
];

const teamData2 = [
    {
        id: 1,
        profileName: 'Iman Adipurnama',
        description: 'Director of YIPA Taiwan',
        role: 'Executive Director',
        image: 'https://napheart.sirv.com/Images/profiles/iman.webp',
        linkedinUrl: 'https://www.linkedin.com/in/iman-adipurnama-770298167/',
        instagramUrl: 'https://www.instagram.com/iman_vergurd/',
    },
    {
        id: 2,
        profileName: 'Michael W Pratama',
        role: 'Vice Director',
        description: 'Vice Director of YIPA Taiwan',
        image: 'https://napheart.sirv.com/Images/profiles/mike.webp',
        linkedinUrl: 'https://www.linkedin.com/in/michaelwpratama/',
        instagramUrl: 'https://www.instagram.com/askmikechristopher/',
    },
    {
        id: 3,
        profileName: 'Jefferson Singgih',
        role: 'Vice Director',
        description: 'Vice Director of YIPA Taiwan',
        image: 'https://napheart.sirv.com/Images/profiles/jeff.webp',
        linkedinUrl: 'https://www.linkedin.com/in/jefferson-singgih-379b6a161/',
        instagramUrl: 'https://www.instagram.com/jeffersonsinggih/',
    },
    {
        id: 4,
        profileName: 'Rosdyana Kusuma',
        role: 'Technical Director',
        description: 'Technical Director of YIPA Taiwan',
        image: 'https://napheart.sirv.com/Images/profiles/ross.webp',
        linkedinUrl: 'https://www.linkedin.com/in/rosdyanakusuma/',
        instagramUrl: 'https://www.instagram.com/r_kusuma/',
    },
    {
        id: 5,
        profileName: 'Fenny Junaidy',
        role: 'Program Director',
        image: 'https://napheart.sirv.com/Images/profiles/fenny.webp',
        linkedinUrl: 'https://www.linkedin.com/in/fennyjunaidy/',
        instagramUrl: 'https://www.instagram.com/fennyjunaidy/',
    },
    {
        id: 6,
        profileName: 'Hanke Marcelino',
        role: 'Operating Director',
        image: 'https://napheart.sirv.com/Images/profiles/hanke.webp',
        linkedinUrl: 'https://www.linkedin.com/in/hanke-marcelino-576463190/',
        instagramUrl: 'https://www.instagram.com/hankemarcelino/',
    },
    {
        id: 7,
        profileName: 'Alvin Wijaya',
        role: 'Member Growth',
        image: 'https://napheart.sirv.com/Images/profiles/alvin.webp',
        linkedinUrl: 'https://www.linkedin.com/in/alvin-wijaya-027a701a4/',
        instagramUrl: 'https://www.instagram.com/alvinnnw/',
    },
    {
        id: 8,
        profileName: 'Awan Gunarso',
        role: 'Member Growth',
        image: 'https://napheart.sirv.com/Images/profiles/awan.webp',
        linkedinUrl: 'https://www.linkedin.com/in/awan-gunarso-4853321b6/',
        instagramUrl: 'https://www.instagram.com/a.gunarso92/',
    },
    {
        id: 9,
        profileName: 'Nico Hokita',
        role: 'Program Director',
        image: 'https://napheart.sirv.com/Images/profiles/nico.webp',
        linkedinUrl: 'https://www.linkedin.com/in/nicohokita/',
        instagramUrl: 'https://www.instagram.com/nicohokita/',
    },
    {
        id: 10,
        profileName: 'Heni Vinesia',
        role: 'Social Media Specialist',
        image: 'https://napheart.sirv.com/Images/profiles/heni.webp',
        linkedinUrl: 'https://www.linkedin.com/in/heni-vinesia-b45095100/',
        instagramUrl: 'https://www.instagram.com/heni_vinesia/',
    },
    {
        id: 11,
        profileName: 'Fathin Aulia',
        role: 'Social Media Specialist',
        image: 'https://napheart.sirv.com/Images/profiles/fathin.webp',
        linkedinUrl:
            'https://www.linkedin.com/in/fathin-nur-aulia-husna-b57794179/',
        instagramUrl: 'https://www.instagram.com/fathinbasha/',
    },
    {
        id: 12,
        profileName: 'Toko Sugiharto',
        role: 'Social Media Specialist',
        image: 'https://napheart.sirv.com/Images/profiles/toko.webp',
        linkedinUrl:
            'https://www.linkedin.com/in/toko-sugiharto-%E6%89%98%E5%85%8B-a25817105/',
        instagramUrl: 'https://www.instagram.com/soe_tokko/',
    },
    {
        id: 13,
        profileName: 'Indri Leonardi',
        role: 'Social Media Specialist',
        image: 'https://napheart.sirv.com/Images/profiles/indri.webp',
        linkedinUrl: 'https://www.linkedin.com/in/',
        instagramUrl: 'https://www.instagram.com/',
    },
    {
        id: 14,
        profileName: 'Jeffrey Susanto',
        role: 'Program Director',
        image: 'https://napheart.sirv.com/Images/profiles/male-dummy.jpg',
        linkedinUrl: 'https://www.linkedin.com/in/jeffrey-susanto/',
        instagramUrl: 'https://www.instagram.com/jeffreylioe/',
    },
    {
        id: 15,
        profileName: 'Natasha Morla',
        role: 'Program Director',
        image: 'https://napheart.sirv.com/Images/profiles/morla.webp',
        linkedinUrl: 'https://www.linkedin.com/in/natashamorla/',
        instagramUrl: 'https://www.instagram.com/natashamorla/',
    },
];

const Team = (props: any) => {
    const { season } = props;
    return (
        <Box bg={useColorModeValue('gray.100', 'gray.700')}>
            <Container maxW={'9xl'} py={16} as={Stack} spacing={12}>
                <Stack spacing={0} align={'center'}>
                    <Heading>Season {season} Team Members</Heading>
                    <Text fontSize={{ base: 'md', lg: 'lg' }} maxW={'3xl'}>
                        Introducing Season {season} Team Members Meet our
                        talented team for Season {season}, driving innovation
                        and delivering exceptional results. Together, we strive
                        for success, fueled by passion and a shared vision. Join
                        us on our journey of excellence.
                    </Text>
                </Stack>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                    {season == '1' &&
                        teamData.map(team => (
                            <ProfileCard
                                key={team.id}
                                profileName={team.profileName}
                                role={team.role}
                                imageUrl={team.image}
                                linkedinUrl={team.linkedinUrl}
                                instagramUrl={team.instagramUrl}
                            />
                        ))}

                    {season == '2' &&
                        teamData2.map(team => (
                            <ProfileCard
                                key={team.id}
                                profileName={team.profileName}
                                role={team.role}
                                imageUrl={team.image}
                                linkedinUrl={team.linkedinUrl}
                                instagramUrl={team.instagramUrl}
                            />
                        ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default Team;
