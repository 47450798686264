import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import DynamicImagePreview from '../DynamicImagePreview';

interface TimelineEventProps {
    date: string;
    title: string;
    description: string;
    imagePath: string;
}

const TimelineEvent = ({ date, title, description, imagePath }: TimelineEventProps) => {
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const cardBorderColor = useColorModeValue('gray.300', 'gray.600');

    return (
        <Flex align="center">
            <Box position="relative" minWidth={8} padding={4}>
                <Box
                    backgroundColor={cardBgColor}
                    borderWidth={1}
                    borderColor={cardBorderColor}
                    borderRadius="xl"
                    padding={4}
                    textAlign="center"
                >
                    <Text fontWeight="bold" marginBottom={2}>
                        {date}
                    </Text>
                    <Text fontWeight="bold" fontSize="xl" marginBottom={2}>
                        {title}
                    </Text>
                    <Text align={"justify"}>{description}</Text>
                    <Flex width="100%" height="100%" alignItems="center" justifyContent="center" padding={4}>
                        <DynamicImagePreview imagePath={imagePath} title={title} />
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

interface VerticalTimelineProps {
    events: TimelineEventProps[];
}

const VerticalTimeline = ({ events }: VerticalTimelineProps) => {
    return (
        <Box position="relative">
            {events.map((event, index) => (
                <Box key={index}>
                    <TimelineEvent {...event} />
                </Box>
            ))}
        </Box>
    );
};

export default VerticalTimeline;
