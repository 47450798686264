import {
    Flex,
    useColorModeValue,
    Box,
  } from '@chakra-ui/react';
  
  export default function InstagramFeeds() {
    return (
        <Flex
        minH={'50vh'}
        align={'center'}
        justify={'center'}
        py={12}
        bg={useColorModeValue('gray.50', 'gray.800')}>
         <Box w='100%' p={4} borderRadius='lg' overflow='hidden'>
            <iframe
              title="My Embedded Content"
              src="https://widget.elfsig.ht/aebd5ca2-3f90-4681-bbdb-365c94c45214"
              width="100%"
              height="650"
            />
            </Box>
        </Flex>
      );
  }