import {
    Box,
    chakra,
    SimpleGrid,
} from '@chakra-ui/react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function BasicStatistics() {
    const lineChartOptions = {
        chart: {
            type: 'column',
            height: 400,
        },
        title: {
            text: 'Participants over the years',
        },
        xAxis: {
            categories: ['2020', '2021', '2022', '2023'],
        },
        yAxis: {
            title: {
                text: 'Number of participants',
            },
        },
        series: [
            {
                name: 'Participants',
                data: [108, 189, 172, 331],
            },
        ],
    };

    const pieChartOptions = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Member Composition'
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: true,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'Percentage',
                colorByPoint: true,
                data: [
                    {
                        name: 'Industry',
                        y: 0.28
                    },
                    {
                        name: 'Academic',
                        y: 1.67
                    },
                    {
                        name: 'Banking & Financial Services',
                        y: 2.78
                    },
                    {
                        name: 'Retail, Agency & Marketing',
                        y: 8.61
                    },
                    {
                        name: 'Students',
                        y: 47.50
                    },
                    {
                        name: 'Technology, Manufacture, Transportion & Logistics',
                        y: 28.06
                    },
                    {
                        name: 'Other',
                        y: 11.11
                    }
                ]
            }
        ]
    };

    return (
        <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            <chakra.h1
                textAlign={'center'}
                fontSize={'4xl'}
                py={10}
                fontWeight={'bold'}
            >
                YIPA Taiwan in numbers
            </chakra.h1>
            <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 5, lg: 8 }}
                alignItems="center"
                justifyItems="center"
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={lineChartOptions}
                />
                <HighchartsReact
                    highcharts={Highcharts}
                    options={pieChartOptions}
                />
            </SimpleGrid>
        </Box>
    );
}
